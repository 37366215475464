import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    FormText,
    Alert
} from 'reactstrap';
import PropTypes from "prop-types";
import { toast } from 'react-toastify';

import TaxableSwitch from "../../atoms/inputs/TaxableSwitch.js";
import Account from "@kjdelectronics/ps-quotetool-domain/obj/account/Account.js";
import { isEmailValid } from "@kjdelectronics/ps-quotetool-domain/domain/helper/email.validator.js";
import { getUrlSegmentFromCompanyId } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import AccountNameSearchInput from "../../atoms/AccountNameSearchInput.js";

function NewAccountModal({isOpen, onClose, onAccountSelected, quoteToolClient,
                             email: initialEmail, companyId
                         }) {
    const [email, setEmail] = useState(initialEmail || '');
    const [customerName, setCustomerName] = useState('');
    const [accountName, setAccountName] = useState('');
    const [phone, setPhone] = useState('');
    const [isTaxable, setIsTaxable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // Email validation-related states
    const [emailValid, setEmailValid] = useState(null); // can be true/false/null
    const [emailWarning, setEmailWarning] = useState(false);
    const [emailFeedback, setEmailFeedback] = useState('');
    const [accountEmailMatches, setAccountEmailMatches] = useState([]);

    // On mount (similar to componentDidMount), validate the email if present
    useEffect(() => {
        // Only validate if we have a non-empty initial email
        if (email) {
            validateEmail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateEmail = async () => {
        if (email) {
            if (!isEmailValid(email)) {
                setAccountEmailMatches(null);
                setEmailValid(false);
                setEmailWarning(false);
                setEmailFeedback('The provided email is not valid');
                return;
            }

            try {
                // Attempt to fetch accounts by this email
                const searchResults = await quoteToolClient.getAccounts(email).catch(err => {
                    if (err.code === 404) {
                        return [];
                    }
                    throw err;
                });

                if (searchResults.length === 1) {
                    // There's exactly one account with this email
                    setAccountEmailMatches(searchResults);
                    setEmailValid(null);
                    setEmailWarning(true);
                    setEmailFeedback(
                        'There is exactly 1 account that matches this email. ' +
                        'Click "Use Existing Account" to select it or proceed to create a new account.'
                    );
                } else if (searchResults.length > 1) {
                    setAccountEmailMatches(searchResults);
                    setEmailValid(null);
                    setEmailWarning(true);
                    setEmailFeedback(
                        `There are ${searchResults.length} accounts that match this search. Are you sure you want to create a new account?`
                    );
                } else {
                    // No accounts found
                    setAccountEmailMatches(searchResults);
                    setEmailValid(true);
                    setEmailWarning(false);
                    setEmailFeedback('That email is not in the system!');
                }
            } catch (error) {
                console.error('Error validating email', error);
                setAccountEmailMatches([]);
                setEmailValid(false);
                setEmailFeedback('Error checking email availability');
            }
        }
    };

    const handleSubmit = async () => {
        const account = new Account({
            name: accountName,
            isTaxable,
            contacts: [
                {
                    email,
                    name: customerName,
                    phoneNumber: phone,
                }
            ],
            defaults: {
                companyId
            }
        });

        // Validate locally
        const localValidation = account.getValidationErrors();
        if (localValidation.isInvalid) {
            toast.error('Account does not meet minimum required fields. Must provide contact email');
            return;
        }

        try {
            setIsLoading(true);
            console.log('Submitting new account:', account);
            const newAccount = await quoteToolClient.createUpdateAccount(account);
            toast.success(`New Account successfully created`);
            console.log('New Account created:', newAccount);
            onAccountSelected(newAccount);
            onClose();
        } catch (error) {
            setIsLoading(false);
            console.error('Error creating account', error);
            toast.error('Something went wrong, account creation failed');
        }
    };

    const handleClose = () => {
        console.log("Closing new customer modal...");
        onClose();
    };

    // Helper for conditionally rendering "Select Existing Account"
    const isSingleExistingAccountFound =
        accountEmailMatches && accountEmailMatches.length === 1;

    if (!isOpen) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} toggle={handleClose} centered>
            <ModalHeader toggle={handleClose}>Create Account</ModalHeader>
            <ModalBody>
                <Form>
                    <h6 className="text-center">Primary Contact</h6>
                    <FormGroup>
                        <Label for="email">
                            Contact Email <span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="customer@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={validateEmail}
                            valid={emailValid === true || emailWarning === true}
                            invalid={emailValid === false}
                            required
                            tabIndex={1}
                        />
                        {emailValid === true && (
                            <FormFeedback valid>{emailFeedback}</FormFeedback>
                        )}
                        {emailValid === false && (
                            <FormFeedback>{emailFeedback}</FormFeedback>
                        )}
                        {emailWarning && (
                            <FormFeedback valid className="warning-color">
                                {emailFeedback}
                            </FormFeedback>
                        )}
                        {!isSingleExistingAccountFound && (
                            <FormText>
                                You can add more contacts to the account later
                            </FormText>
                        )}
                    </FormGroup>

                    {isSingleExistingAccountFound && (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {/* Example of how to display matched account info */}
                            {accountEmailMatches[0].toString()}
                            <Button
                                className="new-customer-button"
                                color="success"
                                onClick={() => onAccountSelected(accountEmailMatches[0])}
                            >
                                Select Existing Account
                            </Button>
                        </div>
                    )}

                    <FormGroup>
                        <Label for="customerName">Contact Name</Label>
                        <Input
                            type="text"
                            name="customerName"
                            id="customerName"
                            className="bootstrap-input"
                            placeholder="John Smith"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            tabIndex={2}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="phone">Contact Phone</Label>
                        <Input
                            type="phone"
                            name="phone"
                            id="phone"
                            placeholder="+1 555-222-3333"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            tabIndex={3}
                        />
                    </FormGroup>

                    <hr style={{ borderTop: '2px solid' }} />
                    <h6 className="text-center">Account Info</h6>

                    <AccountNameSearchInput
                        accountName={accountName}
                        onAccountNameChange={setAccountName}
                        quoteToolClient={quoteToolClient}
                        companyId={companyId}
                        onAccountSelected={onAccountSelected}
                        label="Account Name"
                        tabIndex={4}
                    />

                    <TaxableSwitch
                        tabIndex={5}
                        isTaxable={isTaxable}
                        onChange={setIsTaxable}
                    />

                    {!isTaxable && (
                        <Alert color="warning" className="mt-2">
                            You are free to create this account as not taxable.
                            However, a warning will be displayed until you visit the
                            account detail page and upload a valid tax exempt certificate
                            in the documents section.
                        </Alert>
                    )}
                </Form>
            </ModalBody>

            <ModalFooter>
                <Button
                    tabIndex={6}
                    className="new-customer-button"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? 'Creating...' : 'Create Account'}
                </Button>
                <Button className="close-button-styles" onClick={handleClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

NewAccountModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccountSelected: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    email: PropTypes.string,
    tabIndex: PropTypes.number,
    companyId: PropTypes.number.isRequired
};

export default NewAccountModal;