import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    Label,
    Input,
    FormFeedback,
    FormText,
    Button
} from 'reactstrap';
import { getUrlSegmentFromCompanyId } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";

/**
 * A reusable component for validating and selecting an Account by name.
 *
 * Props:
 * - accountName (string): The current account name.
 * - onAccountNameChange (func): Callback for updating the account name in the parent.
 * - quoteToolClient (object): API client with a method getAccountsByNameOnly.
 * - companyId (number): The ID for the company; used for domain-specific segmentation.
 * - onAccountSelected (func): Callback to handle selecting an existing account.
 * - label (string): Optional label to display above the input (defaults to "Account Name").
 */
function AccountNameSearchInput({accountName, onAccountNameChange, quoteToolClient,
                                    companyId, onAccountSelected, label = "Account Name", tabIndex
                                }) {
    const [nameValid, setNameValid] = useState(null); // true/false/null
    const [nameWarning, setNameWarning] = useState(false);
    const [nameFeedback, setNameFeedback] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [accountNameMatches, setAccountNameMatches] = useState([]);

    const urlSegment = getUrlSegmentFromCompanyId(companyId);

    /**
     * Searches for existing accounts by name (called onBlur or onChange).
     * Mimics the "email" validation style:
     *  - If 1 match => warn user
     *  - If multiple matches => also warn
     *  - If none => valid
     */
    const validateAccountName = async (name) => {
        // Basic guard: if too short or empty, just reset states
        if (!name || name.trim().length < 5) {
            setAccountNameMatches([]);
            setNameValid(null);
            setNameWarning(false);
            setNameFeedback('');
            return;
        }

        setIsSearching(true);
        try {
            const results = await quoteToolClient
                .getAccountsByNameOnly(name, { companySegment: urlSegment })
                .catch((err) => {
                    // If no accounts found by the server, return empty array
                    if (err.code === 404) {
                        return [];
                    }
                    throw err;
                });

            if (results.length === 0) {
                // No matches => valid
                setAccountNameMatches([]);
                setNameValid(true);
                setNameWarning(false);
                setNameFeedback('No existing accounts found with that name.');
            } else if (results.length === 1) {
                // Exactly one match => warn
                setAccountNameMatches(results);
                setNameValid(null);
                setNameWarning(true);
                setNameFeedback(
                    'There is exactly 1 account with this name. ' +
                    'You can select it below or proceed to create a new one.'
                );
            } else {
                // Multiple matches => warn
                setAccountNameMatches(results);
                setNameValid(null);
                setNameWarning(true);
                setNameFeedback(
                    `There are ${results.length} accounts that match this name. ` +
                    'Consider selecting an existing account or using a different name.'
                );
            }
        } catch (error) {
            console.error('Error searching by account name:', error);
            setAccountNameMatches([]);
            setNameValid(false);
            setNameWarning(false);
            setNameFeedback('Error occurred while checking for account name availability.');
        } finally {
            setIsSearching(false);
        }
    };

    // Helper to handle input changes
    const handleNameChange = (e) => {
        const newValue = e.target.value;
        onAccountNameChange(newValue);
    };

    // Helper for single existing account
    const singleAccountFound = accountNameMatches.length === 1;

    return (
        <FormGroup>
            <Label for="accountName">{label}</Label>
            <Input
                type="text"
                name="accountName"
                id="accountName"
                placeholder="ACME, INC"
                value={accountName}
                tabIndex={tabIndex}
                onChange={handleNameChange}
                onBlur={() => validateAccountName(accountName)}
                valid={nameValid === true || nameWarning === true}
                invalid={nameValid === false}
            />
            {/* Valid or invalid feedback */}
            {nameValid === true && (
                <FormFeedback valid>
                    {nameFeedback}
                </FormFeedback>
            )}
            {nameValid === false && (
                <FormFeedback>
                    {nameFeedback}
                </FormFeedback>
            )}
            {/* Warning feedback (similar approach as your email logic) */}
            {nameWarning && (
                <FormFeedback valid className="warning-color">
                    {nameFeedback}
                </FormFeedback>
            )}
            {/* Can show searching status below */}
            {isSearching && <FormText>Checking for existing accounts...</FormText>}

            {/* Show a "Select Existing Account" if exactly one match found */}
            {singleAccountFound && (
                <div className="d-flex flex-column align-items-start mt-2">
          <span style={{ fontSize: '0.9rem', color: '#777' }}>
            {accountNameMatches[0].toString()}
          </span>
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => onAccountSelected(accountNameMatches[0])}
                    >
                        Select Existing Account
                    </Button>
                </div>
            )}
        </FormGroup>
    );
}

AccountNameSearchInput.propTypes = {
    accountName: PropTypes.string.isRequired,
    onAccountNameChange: PropTypes.func.isRequired,
    quoteToolClient: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    onAccountSelected: PropTypes.func.isRequired,
    label: PropTypes.string
};

export default AccountNameSearchInput;
