import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Row, Col } from "reactstrap";
import QuoteDetail from "../molecules/QuoteDetail.js";
import ProductForm from "./ProductForm.js";
import ShippingOptions from '../atoms/inputs/ShippingOptions.js';
import NotesField from '../atoms/inputs/NotesField.js';
import Quote from "@kjdelectronics/ps-quotetool-domain/obj/quote/Quote";
import PatchQuote from "@kjdelectronics/ps-quotetool-domain/domain/PatchQuote";
import LoadingSpinner from "../atoms/loading/LoadingSpinner.js";
import { toast } from "react-toastify";
import PaymentAndGeneralTermsFlexibleDropdown from "../atoms/inputs/PaymentAndGeneralTermsFlexibleDropdown";
import { getDefaultCountryForCompanyId } from "@kjdelectronics/ps-quotetool-domain/domain/helper/country.default.js";
import DocumentUploader from "../molecules/DocumentUploader.js";
import {cloneQuoteAndOpen} from "../../helper/quote.cloner.js";
import {useNavigate} from "react-router-dom";
import {getCompanyIdFromUrlSegment} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import QuoteStatusActions from "../molecules/QuoteStatusActions.js";

const QuoteOrganism = ({
                           quoteToolClient,
                           accountId,
                           companyUrlSegment,
                           existingQuoteRef,
                           onQuoteCreated,
                           customerEmail,
                           helpScoutId,
                           isUSCompany,
                           currencyRates,

                       }) => {
    const companyId = getCompanyIdFromUrlSegment(companyUrlSegment);

    const [quote, setQuote] = useState(
        new Quote({
            companyId,
            currencyRates,
            shipping: { shippingService: "", shippingPrice: null },
            customer: { email: customerEmail },
            detail: {
                notes: "",
                helpScoutId,
                includeBankingDetails: false,
            },
            addresses: {
                shippingAddress: { country: getDefaultCountryForCompanyId(companyId) },
            },
            lineItems: [],
        })
    );
    const [isLoading, setIsLoading] = useState(Boolean(existingQuoteRef));
    const [errorMessage, setErrorMessage] = useState(
        existingQuoteRef && isNaN(existingQuoteRef) ? "Invalid Quote Id" : null
    );
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isCloning, setIsCloning] = useState(false);
    const navigate = useNavigate();

    const loadQuote = useCallback(async () => {
        if (existingQuoteRef && !errorMessage) {
            try {
                const loadedQuote = await quoteToolClient.getQuoteByRef({ref: existingQuoteRef, companyUrlSegment});
                setQuote(loadedQuote);
            } catch (err) {
                setErrorMessage(err.message || "Error Loading Quote");
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }, [existingQuoteRef, errorMessage, quoteToolClient]);

    useEffect(() => {
        loadQuote();
    }, [loadQuote]);

    const patchQuote = ({ keyArray, newValue }) => {
        setQuote((prevQuote) => PatchQuote.patchQuote({ quote: prevQuote, keyArray, newValue }));
    };

    const changeStatus = (newStatus) =>{
        const updatedQuote = PatchQuote.patchQuote({ quote: quote, keyArray: ["status", "current"], newValue: newStatus });
        return handleSubmit(updatedQuote);
    }

    const patchQuoteAction = ({ action, data }) => {
        setQuote((prevQuote) => PatchQuote.patchQuoteAction({ quote: prevQuote, action, data }));
    };

    const patchLineItem = ({ lineItemIndex, key, value }) => {
        setQuote((prevQuote) => PatchQuote.patchLineItem({ quote: prevQuote, lineItemIndex, key, value }));
    };

    const setLineItem = ({ lineItemIndex, newLineItemObject }) => {
        setQuote((prevQuote) => PatchQuote.setLineItem({ quote: prevQuote, lineItemIndex, newLineItemObject }));
    };

    const handleSubmit = async (quote=null) => {
        if(!quote)
            throw new Error(`DEV ERROR: quote must always be passed in to handleSubmit in QuoteOrganisim.js. Pass in the state if unsure`);

        if (quote.account.getValidationErrors().isInvalid) {
            toast.error("Customer Account selection is required. Select an existing account or create a new one.", {
                autoClose: 2500,
            });
            return;
        }

        try {
            const updatedQuote = await quoteToolClient.createUpdateQuote(quote);
            if (!quote.ref) {
                onQuoteCreated(updatedQuote.ref);
            } else {
                setQuote(updatedQuote);
            }

            toast.success(`Quote ${updatedQuote.ref} ${!quote.ref ? "created" : "updated"} successfully!`, {
                autoClose: 5000,
            });
            return updatedQuote;
        } catch (error) {
            console.error("Error creating/updating quote:", error);
            toast.error("An unexpected error occurred. Please try again.");
        }
    };

    const saveAndOpenPdf = async () => {
        const savedQuote = await handleSubmit(quote);
        if (savedQuote?.ref) {
            const pdfUrl = `/v1/${companyUrlSegment}/quotes/${savedQuote.ref}.pdf`;
            window.open(pdfUrl);
        }
    };

    const cloneAndOpen = async (quote) => {
        if (quote && quote.ref) {
            try {
                setIsCloning(true);
                await cloneQuoteAndOpen({quote, quoteToolClient: quoteToolClient, navigate});
            } catch (error) {
                console.error(error);
                toast.error(`Failed to clone quote ${quote.ref}`);
            }
        }
    };

    const refreshDataFromBc = async () => {
        setLoadingMessage("Please wait while product data is refreshed from BC. This may take some time...");
        const savedQuote = await handleSubmit(quote);

        if (savedQuote?.ref) {
            try {
                await quoteToolClient.syncProductDataIntoSaturnForQuoteRef({ref: savedQuote.ref, companyUrlSegment});
                const updatedQuote = await quoteToolClient.getQuoteByRef({ref: existingQuoteRef, companyUrlSegment});
                setQuote(updatedQuote);
                toast.success("Product data updated from BC");
            } catch (err) {
                console.error(err);
                toast.error("Failed to update product data from BC");
            } finally {
                setLoadingMessage("");
            }
        } else {
            setLoadingMessage("");
        }
    };

    if (errorMessage) {
        return (
            <Alert color="danger" className="mx-4 mt-3 text-center">
                <span className="fw-bold">Invalid Quote Id</span>
            </Alert>
        );
    }

    if (isLoading) {
        return <LoadingSpinner isLoading={isLoading} message={loadingMessage} />;
    }

    return (
        <div>
            <fieldset disabled={quote.isLocked}>
                {loadingMessage && <LoadingSpinner isLoading fullPage message={loadingMessage}/>}
                <QuoteDetail
                    quote={quote}
                    accountId={accountId}
                    patchQuote={patchQuote}
                    patchQuoteAction={patchQuoteAction}
                    quoteToolClient={quoteToolClient}
                    companyId={companyId}
                    isUSCompany={isUSCompany}
                />
                <ProductForm
                    quoteToolClient={quoteToolClient}
                    lineItems={quote.lineItems}
                    patchQuote={patchQuote}
                    patchQuoteAction={patchQuoteAction}
                    patchLineItem={patchLineItem}
                    setLineItem={setLineItem}
                    quote={quote}
                    isUSCompany={isUSCompany}
                    companyId={companyId}
                    currencyRates={currencyRates}
                companyUrlSegment={companyUrlSegment}
                setQuote={setQuote}/>
                <Row className="mt-3 ms-3 me-3 d-sm-block d-md-flex">
                    <Col md={10}>
                        <Row>
                            <Col md={4}>
                                <ShippingOptions
                                    value={quote.shipping.shippingService}
                                    onChange={(value) => patchQuote({
                                        keyArray: ["shipping", "shippingService"],
                                        newValue: value
                                    })}
                                    quote={quote}
                                    isUSCompany={
                                        isUSCompany
                                    }/>
                            </Col>
                            <Col md={4}>
                                <PaymentAndGeneralTermsFlexibleDropdown
                                    header="Payment Terms"
                                    selectedTerm={quote.detail.paymentTermId}
                                    onChange={(e) => patchQuote({
                                        keyArray: ["detail", "paymentTermId"],
                                        newValue: parseInt(e.target.value, 10)
                                    })}
                                    quoteToolClient={quoteToolClient}/>
                            </Col>
                            <Col md={4}>
                                <NotesField value={quote.detail.notes} onChange={(newNotes) => patchQuote({
                                    keyArray: ["detail", "notes"],
                                    newValue: newNotes
                                })}/>
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={quote.detail.includeBankingDetails}
                                        onChange={(e) => patchQuote({
                                            keyArray: ["detail", "includeBankingDetails"],
                                            newValue: e.target.checked
                                        })}
                                        id="includeBankingDetailsCheckbox"
                                    />
                                    <label className="form-check-label" htmlFor="includeBankingDetailsCheckbox">
                                        Include Banking Details In PDF
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-1 ms-5 me-5">
                    <Col>
                        <Button className="close-button-styles" id="quoteSaveBtn" onClick={() => handleSubmit(quote)}>
                            {quote.id ? "Update Quote" : "Create Quote"}
                        </Button>
                        {quote.id && (
                            <Button className="button-styles mx-2" onClick={saveAndOpenPdf}>
                                Save and View PDF
                            </Button>
                        )}
                        {quote.id && (
                            <Button
                                color="primary"
                                outline={true}
                                size="sm"
                                onClick={() => cloneAndOpen(quote)}
                                disabled={isCloning}
                            >
                                Clone
                            </Button>
                        )}
                        {quote.id && (
                            <Button
                                className="button-styles mx-2"
                                color="primary"
                                outline
                                size="sm"
                                onClick={refreshDataFromBc}
                            >
                                Save and Refresh Product Data From BC
                            </Button>
                        )}
                    </Col>
                    <Col>
                        {quote.ref && <QuoteStatusActions quoteStatus={quote.status} onStatusChange={changeStatus}/>}
                    </Col>
                </Row>
                {quote?.id && (
                    <Row className="mt-3 ms-1 me-5">
                        <hr/>
                        <div className="ms-3 me-5 mb-3 overflow-x-auto">
                            <h3>Quote Documents</h3>
                            <DocumentUploader
                                documents={quote.documents || []}
                                client={quoteToolClient}
                                parentType="quotes"
                                onDocumentsUpdated={loadQuote}
                                parentId={quote.id}
                            />
                        </div>
                    </Row>
                )}
            </fieldset>
        </div>
    );
};

QuoteOrganism.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    accountId: PropTypes.string,
    companyId: PropTypes.number.isRequired,
    existingQuoteRef: PropTypes.number,
    onQuoteCreated: PropTypes.func,
    customerEmail: PropTypes.string,
    helpScoutId: PropTypes.string,
    isUSCompany: PropTypes.bool.isRequired,
    currencyRates: PropTypes.object.isRequired,
};

export { QuoteOrganism };
