import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import {formatStatusStringForDisplay} from "../../helper/formatters.js";
import QUOTE_STATUS_COLORS from "../../domain/quote.status.colors.js";
import QuoteStatusBadge from "../atoms/QuoteStatusBadge.js";

const QuoteStatusActions = ({ quoteStatus, onStatusChange }) => {
    return (
        <div>
            <span className="me-3">Current Status: <QuoteStatusBadge status={quoteStatus.current}/></span>
            {Object.entries(quoteStatus.next)
                .filter(([newStatus, label]) => newStatus !== "EXPIRED") //Do not allow manually expiring
                .map(([newStatus, label]) => (
                <Button key={newStatus} color={QUOTE_STATUS_COLORS[newStatus]} className="mx-2" size="sm" onClick={() => onStatusChange(newStatus)}>
                    {label}
                </Button>
            ))}
        </div>
    );
};

export default QuoteStatusActions;
