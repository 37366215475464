import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup,
    Label,
    UncontrolledTooltip,
    Progress
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultipleSkuModal = ({
                              isOpen,
                              toggle,
                              handleSkuAdd,
                              quoteToolClient,
                              companyId,
                              companyUrlSegment,
                              onAddSkus
                          }) => {
    const [input, setInput] = useState('');
    const [result, setResult] = useState({ validatedProducts: [], errors: [], totalAccepted: 0 });
    const [validated, setValidated] = useState(false);
    const [validationInProgress, setValidationInProgress] = useState(false);
    const [validationProgress, setValidationProgress] = useState(0);

    // Update the textarea as the user types and clear any previous validation.
    const handleInputChange = (e) => {
        setInput(e.target.value);
        setValidated(false);
        setResult({ validatedProducts: [], errors: [], totalAccepted: 0 });
        setValidationProgress(0);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    // Validate each SKU and cache the returned product objects.
    const validateSkus = async () => {
        const skus = input
            .split(/[\n,;]+/)
            .map(sku => sku.trim())
            .filter(sku => sku);
        const validatedProducts = [];
        const errors = [];
        setValidationInProgress(true);
        // Process each SKU one by one.
        for (let i = 0; i < skus.length; i++) {
            const sku = skus[i];
            try {
                const product = await quoteToolClient.getProductLineItemBySku({
                    companyUrlSegment,
                    sku
                });
                if (product) {
                    validatedProducts.push(product);
                    // Update the result state so the accepted count reflects the current number.
                    setResult(prev => ({
                        ...prev,
                        validatedProducts: [...validatedProducts],
                        totalAccepted: validatedProducts.length
                    }));
                } else {
                    errors.push(`SKU not found: ${sku}`);
                    setResult(prev => ({
                        ...prev,
                        errors: [...errors]
                    }));
                }
            } catch (error) {
                console.error('Error fetching SKU:', error);
                errors.push(`Error for SKU: ${sku}`);
                setResult(prev => ({
                    ...prev,
                    errors: [...errors]
                }));
            }
            const progress = Math.round(((i + 1) / skus.length) * 100);
            setValidationProgress(progress);
        }
        setValidationInProgress(false);
        setValidated(true);
        const finalResult = {
            validatedProducts,
            errors,
            totalAccepted: validatedProducts.length
        };
        setResult(finalResult);
        return finalResult;
    };

    const handleValidateAndAdd = async () => {
        let validationResult = result;
        // If not already validated, run validation.
        if (!validated) {
            validationResult = await validateSkus();
            if (validationResult.errors.length > 0) {
                toast.error("Cannot add SKUs with errors. Please fix the errors before proceeding.");
                return;
            }
        }
        if (validationResult.errors.length > 0) {
            toast.error("Cannot add SKUs with errors. Please fix the errors before proceeding.");
            return;
        }
        handleSkuAdd(validationResult.validatedProducts);
        handleClose();
    };

    const handleAddWithoutValidation = () => {
        const skus = input
            .split(/[\n,;]+/)
            .map(sku => sku.trim())
            .filter(sku => sku);
        handleSkuAdd(skus);
        handleClose();
    };

    const handleClose = () => {
        setInput('');
        setResult({ validatedProducts: [], errors: [], totalAccepted: 0 });
        setValidated(false);
        setValidationProgress(0);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Add Multiple SKUs</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label htmlFor="skuInput">
                        Enter SKUs (separated by newline, comma, or semicolon):
                    </Label>
                    <Input
                        type="textarea"
                        id="skuInput"
                        value={input}
                        onChange={handleInputChange}
                        style={{ resize: 'none' }}
                    />
                </FormGroup>
                <Progress value={validationProgress} style={{ marginTop: '10px' }} />
                <div>
                    <p>Total Accepted SKUs: {result.totalAccepted}</p>
                    {result.errors.length > 0 && (
                        <ul style={{ color: 'red' }}>
                            {result.errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={handleValidateAndAdd}
                    disabled={validationInProgress || (validated && result.errors.length > 0)}
                    id="validateAddButton2"
                >
                    {validationInProgress ? "Validating..." : "Validate & Add SKUs"}
                </Button>
                <UncontrolledTooltip target="validateAddButton2">
                    This button will only add SKUs if all are valid (custom items will be rejected).
                </UncontrolledTooltip>
                <Button
                    color="primary"
                    onClick={handleAddWithoutValidation}
                    id="addCustomButton"
                >
                    Add SKUs (Include custom)
                </Button>
                <UncontrolledTooltip target="addCustomButton">
                    This button will add SKUs as entered (custom items allowed).
                </UncontrolledTooltip>
                <Button color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default MultipleSkuModal;
