const usdFormatter = value => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
const cadFormatter = value => new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(value);
const eurFormatter = value => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value); // Example for Euro
const gbpFormatter = value => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value); // Example for British Pound
const percentFormatter = value => {
    if(Number.isNaN(value))
        return "0%"
    return new Intl.NumberFormat('en-US', { style: 'percent' }).format(value);
}

const getCurrencyFormatter = currencyCode => {
    switch (currencyCode) {
        case 'USD':
            return usdFormatter;
        case 'CAD':
            return cadFormatter;
        case 'EUR':
            return eurFormatter;
        case 'GBP':
            return gbpFormatter;
        default:
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    }
};

const formatStatusStringForDisplay = (input) => {
    if(!input || !input.toLowerCase)
        return "";

    return input
        .toLowerCase() // Convert to lowercase
        .replace(/_/g, " ") // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export { usdFormatter, cadFormatter, eurFormatter, gbpFormatter, percentFormatter, getCurrencyFormatter, formatStatusStringForDisplay };