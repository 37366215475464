import React from 'react';
import PropTypes from 'prop-types';
import AppContext from "../../context/AppContext";
import { Button, Alert } from "reactstrap";
import { Link } from 'react-router-dom';
import { getUrlSegmentFromCompanyId } from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";

class WelcomePage extends React.Component {
    static contextType = AppContext;

    render() {
        const { user } = this.context;

        // Get company segments for US and CA
        const usCompanySegment = getUrlSegmentFromCompanyId(1006);
        const caCompanySegment = getUrlSegmentFromCompanyId(1000099708);

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    textAlign: "center",
                    padding: "20px",
                }}
            >
                {/* Welcome Message */}
                <h3 className="mb-4">Welcome, {user.firstName}!</h3>

                {/* Info Alert */}
                <Alert color="info" className="mb-4">
                    Please select a company to proceed.
                </Alert>

                {/* Company Selection Buttons */}
                <div className="d-flex gap-3">
                    <Link to={`/${usCompanySegment}/`}>
                        <Button color="primary" size="lg">
                            US Company
                        </Button>
                    </Link>
                    <Link to={`/${caCompanySegment}/`}>
                        <Button color="secondary" size="lg">
                            Canada Company
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
}

WelcomePage.propTypes = {
    exampleBool: PropTypes.bool
};

export { WelcomePage };