import React, { useEffect, useState } from 'react';
import {
    Table,
    Button,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import {getCompanyIdFromUrlSegment} from "@kjdelectronics/ps-quotetool-domain/obj/saturn/SaturnCompanyUtils";
import {getCompanySegment} from "@kjdelectronics/ps-quotetool-domain/domain/helper/companyUtils.js";

const AccountsTable = ({ quoteToolClient, searchQuery }) => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { companyUrlSegment } = useParams();

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                setLoading(true);
                const result = await quoteToolClient.getAccounts(searchQuery, {companySegment: companyUrlSegment });
                setAccounts(result);
                setCurrentPage(1); // Reset to the first page when searchQuery changes
            } catch (error) {
                console.log(error.message);
                if(error.message.includes('404')) {
                    setAccounts([]);
                    setCurrentPage(1);
                }else{
                    toast.error('Error fetching accounts');
                }
            } finally {
                setLoading(false);
            }
        };

        const debounceFetch = setTimeout(() => {
            fetchAccounts();
        }, 100); // 100ms debounce for search

        return () => clearTimeout(debounceFetch); // Clear timeout on component unmount or input change
    }, [searchQuery, quoteToolClient]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (value) => {
        setRowsPerPage(Number(value));
        setCurrentPage(1); // Reset to first page when changing rows per page
    };

    const totalPages = Math.ceil(accounts.length / rowsPerPage);
    const currentAccounts = accounts.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const renderPagination = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <Pagination bsSize="sm">
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink first onClick={() => handlePageChange(1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                </PaginationItem>

                {pageNumbers.map((number) => (
                    <PaginationItem active={number === currentPage} key={number}>
                        <PaginationLink onClick={() => handlePageChange(number)}>
                            {number}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink last onClick={() => handlePageChange(totalPages)} />
                </PaginationItem>
            </Pagination>
        );
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <Table bsSize="sm" responsive>
                <thead>
                <tr>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Primary Contact Name</th>
                    <th>Primary Contact Email</th>
                    <th>Notes</th>
                    <th>Info</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {currentAccounts.map((account) => {
                    const companySegment = getCompanySegment(account);
                    const isNewQuoteAvailable = companySegment !== '';

                    return (
                        <tr key={account.id}>
                            <td>
                                <Link to={`/${companyUrlSegment}/accounts/${account.id}`}>
                                    {account.name || 'Unnamed Account'}
                                </Link>
                            </td>
                            <td>
                                <span className="short-code"
                                      title={account.formattedShortCode}>{account.formattedShortCode}</span>
                            </td>
                            <td>{account.contacts[0]?.name || 'N/A'}</td>
                            <td>{account.contacts[0]?.email || 'N/A'}</td>
                            <td>{account.notes || 'No notes available'}</td>
                            <td>{account.defaults.companyId == null  && <Badge color="danger">No Company</Badge>}</td>
                            <td>
                                {isNewQuoteAvailable ? (
                                    <Link
                                    to={`/${companyUrlSegment}/quotes?accountId=${account.id}`}
                                    style={{ textDecoration: 'none' }}
                                    >
                                        <Button
                                            size="sm"
                                            outline
                                            style={{
                                                color: 'inherit',
                                                textDecoration: 'none',
                                                borderColor: 'inherit',
                                                marginLeft: '15px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlus}
                                                             style={{color: 'green', marginRight: '5px'}}/> New Quote
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        size="sm"
                                        outline
                                        disabled
                                        style={{
                                            color: 'grey',
                                            textDecoration: 'none',
                                            borderColor: 'grey',
                                            marginLeft: '15px'
                                        }}
                                        title="Company or Store ID is required to create a new quote."
                                    >
                                        <FontAwesomeIcon icon={faPlus} style={{color: 'grey', marginRight: '5px'}}/> New
                                        Quote
                                    </Button>
                                )}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>

            {/* Pagination */}
            <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle caret bsSize="sm">
                            Show {rowsPerPage} entries
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => handleRowsPerPageChange(10)}>10</DropdownItem>
                            <DropdownItem onClick={() => handleRowsPerPageChange(50)}>50</DropdownItem>
                            <DropdownItem onClick={() => handleRowsPerPageChange(100)}>100</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {renderPagination()}
            </div>
        </>
    );
};

AccountsTable.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
    searchQuery: PropTypes.string,
};

export default AccountsTable;